<template>
    <div>
        <v-container fluid>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-alert v-if="error" type="error">
                {{ error }}
            </v-alert>
            <div v-else style="background: white;">
                <div class="pageHeight" style="width: auto;margin: 10px;padding: 8px;border: 5px solid #787878;">
                    <table width="100%">
                        <tr>
                            <td width="10%">
                                <img style="height: 120px; width: 300px"
                                    src="https://vierp-test.s3.ap-south-1.amazonaws.com/logo/vu_logo.svg" />
                            </td>
                            <td width="90%">
                                <p style="margin-left:250px;font-weight: bold; font-size:24px;color: darkorange;">
                                    Provisional Admission (AY : {{currentay}})
                                </p>
                            </td>
                        </tr>
                    </table>
                    <hr style="margin-top: 0px;margin-bottom: 0px;">
                    <span style="text-align: justify; font-weight: 500; color:red;">
                        <br />
                        Note : Please do not take printout of this page. Fill proper information here and press "Submit"
                        button at bottom. After submitting form you can take print of the form.
                        <br />
                        <br />
                        <span style="font-weight: bold;">*</span><span style="color:black;"> Fields are madatory</span>
                        <br /><br />
                    </span>

                    <v-row dense>
                        <v-col cols="12" sm="3">
                            <v-text-field dense v-model="email" placeholder="Enter Email" label="Email ID *"
                                prepend-inner-icon="edit" required="true" outlined persistent-hint :rules="emailRules"
                                @change="fetchdata=false"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-autocomplete dense v-model="currentpt" placeholder="Select Programme Type"
                                label="Programme Type *" prepend-inner-icon="mdi-view-list" required="true" outlined
                                persistent-hint :rules="[v => !!v || 'Required']" :items="programtypelist"
                                item-text="name" item-value="id"
                                @change="fetchdata=false;getProgram();"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete dense v-model="currentprogram" placeholder="Select Programme"
                                label="Programme *" prepend-inner-icon="mdi-view-list" required="true" outlined
                                persistent-hint :rules="[v => !!v || 'Required']" :items="programlist"
                                item-text="admissiondisplayname" item-value="id"
                                @change="fetchdata=false;getYear();"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-autocomplete dense v-model="currentyear" placeholder="Select Year" label="Year *"
                                prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                :rules="[v => !!v || 'Required']" :items="yearlist" item-text="display_name"
                                item-value="id" @change="fetchdata=false"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-btn color="primary" dark class="mb-2" @click="proceed()">Fetch</v-btn>
                        </v-col>
                    </v-row>
                    <v-alert v-if="errormsg" type="error">
                        {{ errormsg }}
                    </v-alert>
                    <div v-else-if="fetchdata">
                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <v-simple-table style="border: 1px solid;width:90%;">
                                    <template v-slot:default>
                                        <thead>
                                            <tr style="background:orange;font-weight : 600;">
                                                <td class="text-left">Particulars of the Fees</td>
                                                <td class="text-left">Amount</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Programme Fees</td>
                                                <td>{{provisionadmission.feesamount}}</td>
                                            </tr>
                                            <tr>
                                                <td>First Installment</td>
                                                <td>{{provisionadmission.admissionfees}}</td>
                                            </tr>
                                            <tr v-if="provisionadmission.applicationformfee">
                                                <td>Application Fees</td>
                                                <td>{{provisionadmission.applicationformfee}}</td>
                                            </tr>
                                            <tr style="background:lightgrey;font-weight : 600;">
                                                <td>Amount to be paid for Provisional Admission</td>
                                                <td>{{provisionadmission.totalfees}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-simple-table style="border: 1px solid;width:90%;">
                                    <template v-slot:default>
                                        <thead>
                                            <tr style="background:orange;font-weight : 600;">
                                                <td class="text-left" colspan="2">Programme Details</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-left">Faculty/Stream</td>
                                                <td>{{provisionadmission.stream}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">Department</td>
                                                <td>{{provisionadmission.department}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">Programme name</td>
                                                <td>{{provisionadmission.program}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <br />
                        <v-card style="background:aliceblue;">
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" sm="6">
                                        <v-text-field dense
                                            v-model="provisionadmission.fullname_as_per_previous_marksheet"
                                            placeholder="Enter Full Name as per Last Qualifying Marksheet"
                                            label="Candidate Full Name(As per Last Qualifying Marksheet) *"
                                            prepend-inner-icon="edit" required="true" outlined id="name" persistent-hint
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                                            @input="capitaliseName();"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field dense v-model="provisionadmission.firstName"
                                            placeholder="Enter First Name" label="First Name *"
                                            prepend-inner-icon="edit" required="true" outlined persistent-hint
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field dense v-model="provisionadmission.middleName"
                                            placeholder="Enter Middle Name" label="Middle Name"
                                            prepend-inner-icon="edit" required="true" outlined persistent-hint
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field dense v-model="provisionadmission.lastName"
                                            placeholder="Enter Last Name" label="Last Name *" prepend-inner-icon="edit"
                                            required="true" outlined persistent-hint
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <v-text-field dense type="date" v-model="provisionadmission.date_of_birth"
                                            placeholder="Select Date of Birth" label="Date of Birth *"
                                            prepend-inner-icon="mdi-calendar" required="true" outlined
                                            persistent-hint></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field dense v-model="provisionadmission.birth_place"
                                            placeholder="Enter Place of Birth" label="Place of Birth *"
                                            prepend-inner-icon="edit" required="true" outlined persistent-hint
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.genderid"
                                            placeholder="Select Gender" label="Gender *"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :rules="[v => !!v || 'Required']" :items="genderlist" item-text="type"
                                            item-value="id"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <v-text-field dense v-model="provisionadmission.alternateemail"
                                            placeholder="Enter Alternate Email" label="Alternate Email ID"
                                            prepend-inner-icon="edit" required="true" outlined persistent-hint
                                            :rules="emailRules"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-row dense>
                                            <v-col cols="12" sm="3">
                                                <v-autocomplete dense outlined :items="mobilenocountrycodelist"
                                                    v-model="provisionadmission.mobilenocountrycode" label="Code"
                                                    item-value="id" item-text="code"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="9">
                                                <v-text-field dense v-model="provisionadmission.mobileno"
                                                    placeholder="Enter Mobile No. Registered With WhatsApp"
                                                    label="WhatsApp Mobile No. *" prepend-inner-icon="edit"
                                                    required="true" outlined persistent-hint maxlength="10"
                                                    pattern="\d{10}" title="Please enter exactly 10 digits"
                                                    :rules="[v => v.length == 10 || 'Number Should between 10 digit']"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-row dense>
                                            <v-col cols="12" sm="3">
                                                <v-autocomplete dense outlined :items="mobilenocountrycodelist"
                                                    v-model="provisionadmission.alternatemobilenocountrycode"
                                                    label="Code" item-value="id" item-text="code"></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="9">
                                                <v-text-field dense v-model="provisionadmission.alternate_mobileno"
                                                    placeholder="Enter Alternate Mobile No."
                                                    label="Alternate Mobile No." prepend-inner-icon="edit"
                                                    required="true" outlined persistent-hint maxlength="10"
                                                    pattern="\d{10}" title="Please enter exactly 10 digits"
                                                    :rules="[v => v.length == 10 || 'Number Should between 10 digit']"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <br />
                        <v-card style="background:aliceblue;">
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.countryid"
                                            placeholder="Select Country" label="Country *"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :rules="[v => !!v || 'Required']" :items="countrylist" item-text="name"
                                            item-value="id" @change="fetchState();"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.domacileid"
                                            placeholder="Select Domicile" label="Domicile State*"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined
                                            :disabled=disabledflag persistent-hint :rules="[v => !!v || 'Required']"
                                            :items="domacilelist" item-text="type" item-value="id"
                                            @change="fetchCategory();"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.religionid"
                                            placeholder="Select Religion" label="Religion *"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :rules="[v => !!v || 'Required']" :items="religionlist" item-text="religion"
                                            item-value="id"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.categoryid"
                                            placeholder="Select Category" label="Category *"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :rules="[v => !!v || 'Required']" :items="categorylist" item-text="category"
                                            item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.castid"
                                            placeholder="Select Caste" label="Caste *"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :disabled=disabledflag :rules="[v => !!v || 'Required']" :items="castlist"
                                            item-text="cast" item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="provisionadmission.countryid == indiacountryid">
                                        <v-text-field dense v-model="provisionadmission.aadhar_no"
                                            placeholder="Enter Aadhaar No." label="Aadhaar No. *"
                                            prepend-inner-icon="edit" required="true" outlined persistent-hint
                                            :rules="[v => v.length <= 12 || 'Max 12 digit']"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="provisionadmission.countryid != indiacountryid">
                                        <v-text-field dense v-model="provisionadmission.passport_no"
                                            placeholder="Enter Passport No." label="Passport No. *"
                                            prepend-inner-icon="edit" required="true" outlined persistent-hint
                                            :rules="[v => v.length <= 12 || 'Max 12 digit']"
                                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)'></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <br />
                        <v-card style="background:aliceblue;">
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" sm="3">
                                        <v-text-field dense v-model="provisionadmission.lastqualifyingexam"
                                            placeholder="Select Last Qualifying Examination"
                                            label="Last Qualifying Examination *" prepend-inner-icon="edit"
                                            required="true" outlined persistent-hint
                                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)'></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-autocomplete dense v-model="provisionadmission.lastqualifyingexamstatusid"
                                            placeholder="Select Last Qualifying Examination Status"
                                            label="Last Qualifying Examination Status*"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :rules="[v => !!v || 'Required']" :items="lastqualifyingexamstatuslist"
                                            item-text="status" item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-textarea v-model="provisionadmission.last_attended_college_institute_name"
                                            placeholder="Enter Last Attended College/Institute Name"
                                            label="Last Attended College/Institute Name" prepend-inner-icon="edit"
                                            outlined rows="1" cols="30" clearable></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-textarea v-model="provisionadmission.last_attended_college_institute_addres"
                                            placeholder="Enter Last Attended College/Institute Address"
                                            label="Last Attended College/Institute Address" prepend-inner-icon="edit"
                                            outlined rows="1" cols="30" clearable></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <br />
                        <v-card style="background:aliceblue;">
                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <v-textarea v-model="provisionadmission.communication_address"
                                            placeholder="Enter Full Address For Communication"
                                            label="Full Address For Communication " prepend-inner-icon="edit" outlined
                                            rows="1" cols="30" clearable></v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.stateid"
                                            placeholder="Select State" label="State *"
                                            prepend-inner-icon="mdi-view-list" required="true" outlined persistent-hint
                                            :rules="[v => !!v || 'Required']" :items="statelist" item-text="state"
                                            item-value="id" @change="fetchDistrict();fetchCity();"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.districtid"
                                            placeholder="Select District" label="District"
                                            prepend-inner-icon="mdi-view-list" outlined persistent-hint
                                            :items="districtlist" item-text="district" item-value="id"
                                            clearable></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <v-autocomplete dense v-model="provisionadmission.cityid"
                                            placeholder="Select City" label="City *" prepend-inner-icon="mdi-view-list"
                                            required="true" outlined persistent-hint :rules="[v => !!v || 'Required']"
                                            :items="citylist" item-text="city" item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field dense v-model="provisionadmission.taluka"
                                            placeholder="Enter Taluka" label="Taluka" prepend-inner-icon="edit" outlined
                                            persistent-hint clearable
                                            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field dense v-model="provisionadmission.pin" placeholder="Enter Pincode"
                                            label="Pincode *" prepend-inner-icon="edit" required="true" outlined
                                            persistent-hint :rules="[v => v.length <= 6 || 'Max 6 digit']"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <br />
                        <v-card style="background:aliceblue;">
                            <v-card-text>
                                <!-- <v-radio-group v-model="is_opted" @change="callme()" row>
                            <template v-slot:label>
                                <div><strong style="color:black;">Are you eligible for EWS as per Govt. Norms &nbsp;&nbsp;&nbsp;&nbsp;</strong></div>
                                <div><strong style="color:black;">Do you want to wish to opt for EWS  :</strong></div>
                            </template>
                            <v-radio value="Yes">
                                <template v-slot:label>
                                <div> <strong class="success--text">YES</strong></div>
                                </template>
                            </v-radio>
                            <v-radio value="No">
                                <template v-slot:label>
                                <div> <strong class="red--text">No</strong></div>
                                </template>
                            </v-radio>
                        </v-radio-group> -->
                                <v-row dense v-if="is_opted=='Yes'">
                                    <v-col cols="12" sm="12">
                                        <center>
                                            <span style="color:black;text-align: center ;font-size:18px;">Declaration
                                                and Undertaking<br />
                                                By General – Economically Weaker Section (EWS) Candidates</span>
                                        </center>
                                    </v-col>
                                    <v-col cols="1" sm="1">
                                        <br />
                                        <br />
                                        <br />
                                        <v-checkbox v-model="is_applied_for_ews" color="green"
                                            checked="false"></v-checkbox></v-col>
                                    <v-col cols="10" sm="10">
                                        <p style="text-align: justify; line-height: 1.5">
                                            I’m applying for EWS seat.. I understand that I am required to upload the
                                            necessary
                                            documents in soft copy and to submit hard copy to the eligibility section in
                                            the stipulated time.
                                        </p>
                                        <p style="text-align: justify; line-height: 1.5">
                                            I am aware of the fact that to suffice the government norms, the eligibility
                                            merit list will be
                                            declared after final round of admission.
                                        </p>
                                        <p style="text-align: justify; line-height: 1.5">
                                            In case the candidate has applied for EWS but not selected in the EWS merit
                                            list, he/she will
                                            be allotted in the category specified by the candidate at the time of
                                            admission. Further his/her
                                            eligibility for that category will be checked and if found eligible then as
                                            per the vacancy in the
                                            said branch, the candidate will be admitted (as Non- EWS) and required to
                                            pay complete regular
                                            fees. In case the eligibility and vacancy do not permit, then the
                                            candidate’s admission will
                                            stand cancelled.
                                        </p>
                                    </v-col>
                                    <v-col cols="1" sm="1"></v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card>
                            <!-- {{documentlist}}
                    <div>
                        <h2>Document Upload</h2>
                        <div v-for="document in documentlist" :key="document.id">
                          <label>{{ document.name }}</label>
                          <input type="file" @change="handleFileChange(document)" />
                        </div>
                        <button @click="submitForm">Upload</button>
                      </div> -->
                            <v-row>
                                <v-col v-for="document in documentlist" :key="document.id" cols="4" sm="3">
                                    <v-file-input v-model="document.file" :label="`${document.displayname}${document.iscompulsory ? ' *' : ''}`"
                                        :rules="[isCompulsoryRule(document)]"
                                        accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"></v-file-input>
                                    <!-- <v-card v-if="document.url" class="mt-15 ma-2 pa-2">
                                        <img height="250" width="200" :src="document.url" />
                                    </v-card> -->
                                    <a v-if="document.url" :href="document.url" target="_blank" style="margin-left: 40px;">
                                        <font color="blue">{{document.displayname}}</font>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card>
                        <br />
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-alert type="warning" prominent style="color:black;">
                                    <v-checkbox v-model="checkboxterm" color="red" checked="false"
                                        :label="'I declare that the information provided above is true, complete and correct. I am proceding to pay for Provisional Admission At VU ('+currentay+')'"></v-checkbox>
                                </v-alert>
                                <br>
                                <span style="text-align: justify; font-weight: 500; color:red;">
                                    1. You are suggested to use Nationalized Bank Credentials (Debit / Credit Card / UPI
                                    ) to make payment of fees.<br />
                                    2. The payment receipt will be generated / emailed to you within 24 hours after the
                                    successful payment transaction.<br />
                                    3. In case of payment failure/error, kindly contact VU admission office. We will let
                                    you know the status and will arrange for payment receipt (If any).
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" align="center">
                                <v-btn v-if="checkboxterm" color="primary" dark class="mb-2" :loading="loading"
                                    @click="submitApplication()">
                                    <v-icon>mdi-content-save</v-icon>Submit
                                </v-btn>
                            </v-col>
                        </v-row>
                        <br /><br />
                    </div>
                </div>
                <br>
            </div>
        </v-container>
    </div>
</template>
<script>
    import axios from "axios";
    const CryptoJS = require("crypto-js")
    export default {
        data() {
            return {
                disabledflag: false,
                nadomicile: null,
                nacaste: null,
                error: "",
                errormsg: "",
                snackbar_msg: "",
                color: "",
                snackbar: false,

                email: null,
                indiacountryid: null,
                currentay: null,
                currentayid: null,
                organizationid: null,
                organization_name: null,
                currentpt: null,
                currentprogram: null,
                currentyear: null,
                programtypelist: [],
                programlist: [],
                yearlist: [],
                flag: false,
                fetchdata: false,
                checkboxterm: false,
                is_opted: "No",
                is_applied_for_ews: false,
                provisionadmission: null,
                genderlist: [],
                documentlist: [],
                mobilenocountrycodelist: [],
                religionlist: [],
                citylist: [],
                districtlist: [],
                statelist: [],
                countrylist: [],
                domacilelist: [],
                categorylist: [],
                castlist: [],
                loading: false,
                lastqualifyingexamstatuslist: [],
                emailRules: [
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                ],
            };
        },

        mounted() {
            //this.disabledflag=falsenLoad
            this.prov = this.$route.params._prov;
            if (!this.prov)
                this.prov = this.$route.query._prov
            if (!this.prov) {
                this.error = "You have not filled application form yet. Please fill application form first."
            }
            this.prov = this.decrypt(this.$route.query._prov);
            this.onLoad();
        },

        methods: {
            isCompulsoryRule(document) {
                return v => (document.iscompulsory ? !!v || 'File is required' : true);
            },
            decrypt(src) {
                const passphrase = '987654'
                const bytes = CryptoJS.AES.decrypt(src, passphrase)
                const originalText = bytes.toString(CryptoJS.enc.Utf8)
                return originalText
            },
            capitaliseName() {
                var str = document.getElementById("name").value;
                var array = str.trim().split(" ");
                var name = ""
                for (var i = 0; i < array.length; i++)
                    name = name + " " + (array[i].charAt(0).toUpperCase() + array[i].slice(1));
                this.provisionadmission.fullname_as_per_previous_marksheet = name;
            },
            callme() {
                if (this.is_opted == 'No') {
                    this.is_applied_for_ews = false
                }
            },
            onLoad() {
                const data = {
                    prov: this.prov,
                };
                axios
                    .post("/AppLearnerAdmission/vuadmissionapplication", data)
                    .then((res) => {
                        if (res.data.status == "200") {
                            this.currentay = res.data.currentay;
                            this.organization_name = res.data.organization_name;
                            this.currentayid = res.data.currentayid;
                            this.organizationid = res.data.organizationid;
                            this.programtypelist = res.data.programtypelist;
                            this.email = res.data.email
                            //console.log(res)
                            if (this.programtypelist.length > 0) {
                                if (res.data.programtype)
                                    this.currentpt = res.data.programtype
                                else
                                    this.currentpt = this.programtypelist[0].id;
                                if (res.data.program)
                                    this.currentprogram = res.data.program
                                this.getProgram();
                            }
                            this.error = "";
                        } else if (res.data.status == "401") {
                            this.error = res.data.msg;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something Went Wrong...");
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },

            getProgram() {
                var data = {
                    ptid: this.currentpt,
                }
                axios
                    .post("/AppLearnerAdmission/getProgramByPt", data)
                    .then((res) => {
                        if (res.data.status == "200") {
                            //this.currentprogram = null
                            this.programlist = res.data.programlist;
                            if (this.programlist.length > 0 && !this.currentprogram)
                                this.currentprogram = this.programlist[0].id;
                            if (this.currentprogram)
                                this.getYear()
                            this.error = "";
                        } else if (res.data.status == "401") {
                            this.error = res.data.msg;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something Went Wrong...");
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },

            getYear() {
                var data = {
                    programid: this.currentprogram,
                }
                axios
                    .post("/AppLearnerAdmission/getYearByProgram", data)
                    .then((res) => {
                        if (res.data.status == "200") {
                            this.yearlist = res.data.yearlist;
                            if (this.yearlist.length > 0)
                                this.currentyear = this.yearlist[0].id;
                            else
                                this.currentyear = null;
                            this.error = "";
                        } else if (res.data.status == "401") {
                            this.error = res.data.msg;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something Went Wrong...");
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },

            proceed() {
                this.fetchdata = false;
                this.checkboxterm = false;
                if (!this.email || this.email == null || this.email == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Email !");
                } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
                    this.showSnackbar("#b71c1c", "Please Enter Valid Email !");
                } else if (!this.currentprogram || this.currentprogram == null || this.currentprogram == "") {
                    this.showSnackbar("#b71c1c", "Please Select Program !");
                } else if (!this.currentyear || this.currentyear == null || this.currentyear == "") {
                    this.showSnackbar("#b71c1c", "Please Select Year !");
                } else {
                    var data = {
                        email: this.email,
                        currentayid: this.currentayid,
                        currentprogram: this.currentprogram,
                        currentyear: this.currentyear,
                        prov: this.prov,
                    }
                    axios
                        .post("/AppLearnerAdmission/getvuapplicationdetails", data)
                        .then(res => {
                            if (res.data.status == "200") {
                                this.errormsg = "";
                                this.fetchdata = true;
                                this.provisionadmission = res.data.provisionadmission;
                                this.genderlist = res.data.genderlist;
                                this.mobilenocountrycodelist = res.data.mobilenocountrycodelist;
                                this.religionlist = res.data.religionlist;
                                this.citylist = res.data.citylist;
                                this.districtlist = res.data.districtlist;
                                this.statelist = res.data.statelist;
                                this.countrylist = res.data.countrylist;
                                this.castlist = res.data.castlist;
                                this.indiacountryid = res.data.indiacountryid;
                                this.nadomicile = res.data.nadomicile;
                                this.nacaste = res.data.nacaste;
                                if (!this.provisionadmission.countryid)
                                    this.provisionadmission.countryid = this.indiacountryid;
                                this.domacilelist = res.data.domacilelist;
                                this.categorylist = res.data.categorylist;
                                this.lastqualifyingexamstatuslist = res.data.lastqualifyingexamstatuslist;
                                this.documentlist = res.data.documentlist;
                                this.flag = false;
                                //console.log(res.data.is_applied_for_ews)
                                if (res.data.provisionadmission.is_applied_for_ews) {
                                    this.is_opted = "Yes"
                                    this.is_applied_for_ews = true
                                }
                            } else if (res.data.status == "201") {
                                let routeData = this.$router.push({
                                    name: "vu-admission-application-print",
                                    query: {
                                        rec_no: this.encrypt(res.data.receipt),
                                        provisionadmissionid: this.encrypt(res.data.provisionadmissionid + ""),
                                    }
                                });
                                window.open(routeData.href, '_self');
                            } else if (res.data.status == "401") {
                                this.errormsg = res.data.msg;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch(error => {
                            this.showSnackbar("#b71c1c", "Something Went Wrong...");
                        })
                        .finally(() => {
                            // 'use strict';
                        });
                }
            },

            submitApplication() {
                this.loading=true;
                const formData = new FormData();


                this.documentlist.forEach(document => {

                    if (document.file == null && document.iscompulsory) {
                        if(!document.isupload){
                            this.flag = true;
                        }
                       
                    }
                    if (document.file) {
                        formData.append('documentId', document.id);
                        formData.append(document.id, document.file, document.file.name);
                    }
                });


                if (this.flag) {
                    this.showSnackbar("#b71c1c", "Please Upload All Mandatory Files !");
                    this.flag=false;
                    this.loading=false;
                }
                else if (!this.email || this.email == null || this.email == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Email !");
                } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Valid Email !");
                } else if (!this.currentprogram || this.currentprogram == null || this.currentprogram == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Programme !");
                } else if (!this.currentyear || this.currentyear == null || this.currentyear == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Year !");
                } else if (!this.provisionadmission.fullname_as_per_previous_marksheet || this.provisionadmission.fullname_as_per_previous_marksheet == null || this.provisionadmission.fullname_as_per_previous_marksheet == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Full Name !");
                } else if (!this.provisionadmission.firstName || this.provisionadmission.firstName == null || this.provisionadmission.firstName == "") {
                    this.showSnackbar("#b71c1c", "Please Enter First Name !");
                } else if (!this.provisionadmission.lastName || this.provisionadmission.lastName == null || this.provisionadmission.lastName == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Last Name !");
                } else if (!this.provisionadmission.date_of_birth || this.provisionadmission.date_of_birth == null || this.provisionadmission.date_of_birth == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Date of Birth !");
                } else if (!this.provisionadmission.birth_place || this.provisionadmission.birth_place == null || this.provisionadmission.birth_place == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Place of Birth !");
                } else if (!this.provisionadmission.genderid || this.provisionadmission.genderid == null || this.provisionadmission.genderid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Gender !");
                } else if (this.provisionadmission.alternateemail && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.provisionadmission.alternateemail))) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Valid Alternate Email !");
                } else if (!this.provisionadmission.mobilenocountrycode || this.provisionadmission.mobilenocountrycode == null || this.provisionadmission.mobilenocountrycode == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Mobile No. Country Code !");
                } else if (!this.provisionadmission.mobileno || this.provisionadmission.mobileno == null || this.provisionadmission.mobileno == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Mobile No. !");
                } else if (this.provisionadmission.mobileno.length != 10) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Valid Mobile No. !");
                } else if (this.provisionadmission.alternate_mobileno && (!this.provisionadmission.alternatemobilenocountrycode || this.provisionadmission.alternatemobilenocountrycode == null || this.provisionadmission.alternatemobilenocountrycode == "")) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Alternate Mobile No. Country Code !");
                } else if (this.provisionadmission.alternate_mobileno && this.provisionadmission.alternate_mobileno.length != 10) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Valid Alternate Mobile No. !");
                } else if (!this.provisionadmission.religionid || this.provisionadmission.religionid == null || this.provisionadmission.religionid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Religion !");
                } else if (!this.provisionadmission.castid || this.provisionadmission.castid == null || this.provisionadmission.castid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Cast !");
                } else if (!this.provisionadmission.categoryid || this.provisionadmission.categoryid == null || this.provisionadmission.categoryid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Category !");
                } else if (!this.provisionadmission.domacileid || this.provisionadmission.domacileid == null || this.provisionadmission.domacileid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Domicile !");
                } else if (!this.provisionadmission.lastqualifyingexam || this.provisionadmission.lastqualifyingexam == null || this.provisionadmission.lastqualifyingexam == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Last Qualifying Examination !");
                } else if (!this.provisionadmission.lastqualifyingexamstatusid || this.provisionadmission.lastqualifyingexamstatusid == null || this.provisionadmission.lastqualifyingexamstatusid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Last Qualifying Examination Status!");
                } else if (!this.provisionadmission.countryid || this.provisionadmission.countryid == null || this.provisionadmission.countryid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select Country !");
                } else if (!this.provisionadmission.stateid || this.provisionadmission.stateid == null || this.provisionadmission.stateid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select State !");
                } else if (!this.provisionadmission.cityid || this.provisionadmission.cityid == null || this.provisionadmission.cityid == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Select City !");
                } else if (!this.provisionadmission.pin || this.provisionadmission.pin == null || this.provisionadmission.pin == "") {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Pincode !");
                } else if (this.provisionadmission.pin.length > 6) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Valid Pincode !");
                } else if (this.provisionadmission.countryid == this.indiacountryid && (!this.provisionadmission.aadhar_no || this.provisionadmission.aadhar_no == null || this.provisionadmission.aadhar_no == "")) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Aadhaar No. !");
                } else if (this.provisionadmission.countryid == this.indiacountryid && this.provisionadmission.aadhar_no.length != 12) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Valid Aadhaar !");
                } else if (this.provisionadmission.countryid != this.indiacountryid && (!this.provisionadmission.passport_no || this.provisionadmission.passport_no == null || this.provisionadmission.passport_no == "")) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Enter Passport No. !");
                }
                else if (this.is_opted == 'Yes' && !this.is_applied_for_ews) {
                    this.loading=false;
                    this.showSnackbar("#b71c1c", "Please Accept Under Taking For General – Economically Weaker Section (EWS) Candidates if you wish to Opt for EWS else please Select NO. ");
                } else {
                    this.provisionadmission.prov = this.prov
                    this.provisionadmission.is_applied_for_ews = this.is_applied_for_ews
                    formData.append('provisionadmission', JSON.stringify(this.provisionadmission));
                    axios
                        .post("/AppLearnerAdmission/saveApplicationForm", formData)
                        .then(res => {
                            if (res.data.status == "200") {
                                this.showSnackbar("green", "Application Saved Successfully, Please Make Fees Payment!");
                                this.loading=false;
                                let routeData = this.$router.resolve({
                                    name: "vu-admission-application-fees",
                                    query: { provisionadmissionid: this.encrypt(res.data.provisionadmissionid + ""), },
                                });
                                window.open(routeData.href, '_self');
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch(error => {
                            this.showSnackbar("#b71c1c", "Something Went Wrong...");
                        })
                        .finally(() => {
                            // 'use strict';
                             this.loading=false;
                        });
                }
            },

            encrypt(src) {
                const passphrase = '987654'
                var data = CryptoJS.AES.encrypt(src, passphrase).toString();
                return data
            },

            setFirstMiddleLastName() {
                var namearray = this.provisionadmission.fullname_as_per_previous_marksheet.split(" ");
                if (namearray.length > 0)
                    this.provisionadmission.lastName = namearray[0];
                else
                    this.provisionadmission.lastName = "";

                if (namearray.length > 1)
                    this.provisionadmission.firstName = namearray[1];
                else
                    this.provisionadmission.firstName = "";

                if (namearray.length > 2)
                    this.provisionadmission.middleName = namearray[2];
                else
                    this.provisionadmission.middleName = "";
            },

            fetchState() {
                this.disabledflag = false
                this.provisionadmission.domacileid = ""
                this.provisionadmission.castid = ""
                if (this.provisionadmission.countryid != this.indiacountryid) {
                    this.provisionadmission.domacileid = this.nadomicile
                    this.provisionadmission.castid = this.nacaste
                    this.disabledflag = true
                    this.fetchCategory();
                }
                var tem = {
                    country: this.provisionadmission.countryid
                };
                axios
                    .post("/AppLearnerAdmission/fetchStateVUAdmission", tem)
                    .then((res) => {
                        if (res.data.status == 200 || res.data.status == "200") {
                            this.statelist = res.data.statelist;
                        } else if (res.data.status == 404 || res.data.status == "404") {
                            this.msg = res.data.msg;
                            this.showSnackbar("#b71c1c", this.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong. Please try again.");
                    })
            },

            fetchDistrict() {
                var tem = {
                    country: this.provisionadmission.countryid,
                    state: this.provisionadmission.stateid,
                };
                axios
                    .post("/AppLearnerAdmission/fetchDistrictVUAdmission", tem)
                    .then((res) => {
                        if (res.data.status == 200 || res.data.status == "200") {
                            this.districtlist = res.data.districtlist;
                        } else if (res.data.status == 404 || res.data.status == "404") {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong. Please try again.");
                    })
            },


            fetchCity() {
                var tem = {
                    state: this.provisionadmission.stateid,
                };
                axios
                    .post("/AppLearnerAdmission/fetchCityVUAdmission", tem)
                    .then((res) => {
                        if (res.data.status == 200 || res.data.status == "200") {
                            this.citylist = res.data.citylist;
                        } else if (res.data.status == 404 || res.data.status == "404") {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong. Please try again.");
                    })
            },

            fetchCategory() {
                var tem = {
                    domacileid: this.provisionadmission.domacileid,
                    organizationid: this.provisionadmission.organizationid,
                };
                axios
                    .post("/AppLearnerAdmission/fetchAdmissionCategoryVUAdmission", tem)
                    .then((res) => {
                        if (res.data.status == 200 || res.data.status == "200") {
                            this.categorylist = res.data.categorylist;
                        } else if (res.data.status == 404 || res.data.status == "404") {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong. Please try again.");
                    })
            },

            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
        },
    };
</script>
<style>
    .pageHeight {
        height: auto;
    }
</style>